var AngularJs;
function downloadAndSetup(n) {
	var t = "appInsightsSDK";
	window[t] = "appInsights";
	var e = window[t],
		i =
			window[e] ||
			(function (t) {
				function n(t) {
					a[t] = function () {
						var n = arguments;
						a.queue.push(function () {
							a[t].apply(a, n);
						});
					};
				}
				var a = { config: t, initialize: !0 },
					e = document,
					i = window;
				setTimeout(function () {
					var n = e.createElement("script");
					(n.src = t.url || "https://az416426.vo.msecnd.net/next/ai.2.min.js"),
						e.getElementsByTagName("script")[0].parentNode.appendChild(n);
				});
				try {
					a.cookie = e.cookie;
				} catch (t) {}
				(a.queue = []), (a.version = 2);
				for (
					var c = ["Event", "PageView", "Exception", "Trace", "DependencyData", "Metric", "PageViewPerformance"];
					c.length;

				)
					n("track" + c.pop());
				n("startTrackPage"), n("stopTrackPage"), n("addTelemetryInitializer");
				var r = "Track" + c[0];
				if (
					(n("start" + r),
					n("stop" + r),
					!(
						!0 === t.disableExceptionTracking ||
						(t.extensionConfig &&
							t.extensionConfig.ApplicationInsightsAnalytics &&
							!0 === t.extensionConfig.ApplicationInsightsAnalytics.disableExceptionTracking)
					))
				) {
					n("_" + (c = "onerror"));
					var s = i[c];
					(i[c] = function (n, t, e, i, r) {
						var o = s && s(n, t, e, i, r);
						return !0 !== o && a["_" + c]({ message: n, url: t, lineNumber: e, columnNumber: i, error: r }), o;
					}),
						(t.autoExceptionInstrumented = !0);
				}
				return a;
			})(n);
	window[e] = i;
}
!(function (n) {
	function t() {}
	(n.AppInsightJsV2 || (n.AppInsightJsV2 = {})).ApplicationInsightsConfig = t;
})((AngularJs = AngularJs || {})),
	(function (n) {
		!(function (n) {
			var t =
				((e.isNullOrUndefined = function (n) {
					return null == n;
				}),
				(e.newGuid = function () {
					return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(i, function (n) {
						var t = (16 * Math.random()) | 0;
						return ("x" === n ? t : (3 & t) | 8).toString(16);
					});
				}),
				(e.noop = angular.noop),
				e);
			function e() {}
			n.Utils = t;
			var i = /[xy]/g;
		})(n.AppInsightJsV2 || (n.AppInsightJsV2 = {}));
	})((AngularJs = AngularJs || {})),
	(function (n) {
		var t, e;
		function i(n) {
			var t = this;
			(i.errorOnHttpCall = !1),
				(this._interceptFunction = angular.noop),
				n.decorator("$exceptionHandler", [
					"$delegate",
					function (n) {
						return (
							(t._origExceptionHandler = n),
							function (n) {
								i.errorOnHttpCall || t._interceptFunction(n), t._origExceptionHandler(n);
							}
						);
					}
				]);
		}
		(t = n.AppInsightJsV2 || (n.AppInsightJsV2 = {})),
			(i.prototype.setInterceptFunction = function (n) {
				this._interceptFunction = n;
			}),
			(i.prototype.getPrivateExceptionHanlder = function () {
				return t.Utils.isNullOrUndefined(this._origExceptionHandler) ? angular.noop : this._origExceptionHandler;
			}),
			(e = i),
			(t.ExceptionInterceptor = e);
	})((AngularJs = AngularJs || {})),
	(function (n) {
		var e, t;
		function o(n) {
			var t = this;
			(this._noop = e.Utils.noop),
				(o.interceptFuntion = this._noop),
				n.decorator("$log", [
					"$delegate",
					function (n) {
						return (
							(t._debugFn = n.debug),
							(t._infoFn = n.info),
							(t._warnFn = n.warn),
							(t._logFn = n.log),
							(n.debug = angular.extend(t.delegator(t._debugFn, 0), t._debugFn)),
							(n.info = angular.extend(t.delegator(t._infoFn, 1), t._infoFn)),
							(n.warn = angular.extend(t.delegator(t._warnFn, 2), t._warnFn)),
							(n.log = angular.extend(t.delegator(t._logFn, 1), t._logFn)),
							n
						);
					}
				]);
		}
		(e = n.AppInsightJsV2 || (n.AppInsightJsV2 = {})),
			(o.prototype.setInterceptFunction = function (n) {
				o.interceptFuntion = n;
			}),
			(o.prototype.getPrivateLoggingObject = function () {
				return {
					debug: e.Utils.isNullOrUndefined(this._debugFn) ? this._noop : this._debugFn,
					info: e.Utils.isNullOrUndefined(this._infoFn) ? this._noop : this._infoFn,
					warn: e.Utils.isNullOrUndefined(this._warnFn) ? this._noop : this._warnFn,
					error: this._noop,
					log: e.Utils.isNullOrUndefined(this._logFn) ? this._noop : this._logFn
				};
			}),
			(o.prototype.delegator = function (i, r) {
				function n() {
					var n = [].slice.call(arguments),
						t = n.join(" "),
						e = n.length;
					o.interceptFuntion(t, r, 1 < e ? n[e - 1] : {}), i.apply(null, n);
				}
				for (var t in i) n[t] = i[t];
				return n;
			}),
			(t = o),
			(e.LogInterceptor = t);
	})((AngularJs = AngularJs || {})),
	(function (n) {
		var r, t;
		function e(n, t, e) {
			var i = this;
			(this.config = n),
				this.config.autoLogTracking &&
					t.setInterceptFunction(function (n, t, e) {
						return i.trackTrace({ message: n, severityLevel: t, properties: e });
					}),
				e.setInterceptFunction(function (n, t) {
					return i.trackException({ id: r.Utils.newGuid(), exception: n, severityLevel: 3, properties: t });
				});
		}
		(r = n.AppInsightJsV2 || (n.AppInsightJsV2 = {})),
			(e.prototype.trackEvent = function (n, t) {
				try {
					appInsights.trackEvent(n, t);
				} catch (n) {
					console.warn("Angular application insights Error [trackEvent]: ", n);
				}
			}),
			(e.prototype.startTrackEvent = function (n) {
				try {
					appInsights.startTrackEvent(n);
				} catch (n) {
					console.warn("Angular application insights Error [startTrackEvent]: ", n);
				}
			}),
			(e.prototype.stopTrackEvent = function (n, t, e) {
				try {
					appInsights.stopTrackEvent(n, t, e);
				} catch (n) {
					console.warn("Angular application insights Error [stopTrackEvent]: ", n);
				}
			}),
			(e.prototype.trackPageView = function (n, t) {
				try {
					appInsights.trackPageView(n, t);
				} catch (n) {
					console.warn("Angular application insights Error [trackPageView]: ", n);
				}
			}),
			(e.prototype.startTrackPage = function (n) {
				try {
					appInsights.startTrackPage(n);
				} catch (n) {
					console.warn("Angular application insights Error [startTrackPage]: ", n);
				}
			}),
			(e.prototype.stopTrackPage = function (n, t, e) {
				try {
					appInsights.stopTrackPage(n, t, e);
				} catch (n) {
					console.warn("Angular application insights Error [stopTrackPage]: ", n);
				}
			}),
			(e.prototype.trackMetric = function (n, t) {
				try {
					appInsights.trackMetric(n, t);
				} catch (n) {
					console.warn("Angular application insights Error [trackTrace]: ", n);
				}
			}),
			(e.prototype.trackException = function (n, t) {
				try {
					appInsights.trackException(n, t);
				} catch (n) {
					console.warn("Angular application insights Error [trackException]: ", n);
				}
			}),
			(e.prototype.trackTrace = function (n, t) {
				try {
					appInsights.trackTrace(n, t);
				} catch (n) {
					console.warn("Angular application insights Error [trackTrace]: ", n);
				}
			}),
			(e.prototype.flush = function () {
				try {
					appInsights.flush();
				} catch (n) {
					console.warn("Angular application insights Error [flush]: ", n);
				}
			}),
			(e.prototype.setAuthenticatedUserContext = function (n, t, e) {
				void 0 === e && (e = !1);
				try {
					appInsights.setAuthenticatedUserContext(n, t, e);
				} catch (n) {
					console.warn("Angular application insights Error [setAuthenticatedUserContext]: ", n);
				}
			}),
			(e.prototype.clearAuthenticatedUserContext = function () {
				try {
					appInsights.clearAuthenticatedUserContext();
				} catch (n) {
					console.warn("Angular application insights Error [clearAuthenticatedUserContext]: ", n);
				}
			}),
			(e.prototype._onerror = function (n) {
				try {
					appInsights._onerror(n),
						this.config.enableDebug && console.warn("Angular application insights Error [_onerror]: ", n.message);
				} catch (n) {
					console.warn("Angular application insights Error [_onerror]: ", n);
				}
			}),
			(e.prototype.trackPageViewPerformance = function (n, t) {
				try {
					appInsights.trackPageViewPerformance(n, t);
				} catch (n) {
					console.warn("Angular application insights Error [trackPageViewPerformance]: ", n);
				}
			}),
			(e.prototype.addTelemetryInitializer = function (n) {
				appInsights.addTelemetryInitializer(n);
			}),
			(e.prototype.init = function () {
				if (this.config)
					if (this.config.instrumentationKey)
						try {
							downloadAndSetup(this.config);
						} catch (n) {
							console.warn("Angular application insights Error [downloadAndSetup]: ", n);
						}
					else console.warn("An instrumentationKey value is required to initialize ApplicationInsightsService");
				else
					console.warn(
						"You need to initialize the ApplicationInsightsService with an instrumentationKey while angular js app bootstrap"
					);
			}),
			(t = e),
			(r.ApplicationInsightsService = t);
	})((AngularJs = AngularJs || {})),
	(function (n) {
		!(function (e) {
			var i = angular.module("ApplicationInsightsModule", []);
			i.provider("applicationInsightsService", function () {
				return new n();
			}),
				i.config([
					"$provide",
					function (n) {
						(i.logInterceptor = new e.LogInterceptor(n)), (i.exceptionInterceptor = new e.ExceptionInterceptor(n));
					}
				]);
			var n =
				((t.prototype.configure = function (n) {
					this._options = n;
				}),
				t);
			function t() {
				var t = this;
				this.$get = function () {
					var n = new e.ApplicationInsightsService(t._options, i.logInterceptor, i.exceptionInterceptor);
					return n.init(), n;
				};
			}
			function h(n, t) {
				return n.applicationName + t.path();
			}
			(e.ApplicationInsightsProvider = n),
				i.run([
					"$rootScope",
					"$location",
					"applicationInsightsService",
					function (n, p, g) {
						var o, l, u;
						g.addTelemetryInitializer(function (n) {
							if (((n.tags["ai.operation.name"] = h(g.config, p)), "PageviewData" == n.baseType)) {
								var t = p.search();
								null != typeof t && (n.data.query = t);
							}
							"PageviewPerformanceData" == n.baseType && (n.baseData.uri = p.absUrl());
						}),
							n.$on("$locationChangeStart", function (n, t, e) {
								(u = e), (o = new Date().getTime());
							}),
							n.$on("$locationChangeSuccess", function (n, t) {
								if ((g.config.enableDebug && console.log(n, t, p.path()), !g.config.autoStateChangeTracking)) {
									var e = new Date().getTime() - o,
										i = h(g.config, p);
									t && (i += "#" + t);
									var r = { name: i, url: p.absUrl(), refUri: u, measurements: { routeChangeDuration: e } };
									g.trackPageView(r), g.config.enableDebug && console.log("$locationChangeSuccess->trackPageView", g);
								}
							}),
							n.$on("$stateChangeStart", function (n, t, e, i, r, o) {
								(u = t), g.config.autoStateChangeTracking && (l = new Date().getTime());
							}),
							n.$on("$stateChangeSuccess", function (n, t, e, i, r, o) {
								if (
									(g.config.enableDebug && console.log(n, t, e, i, r, o, p.path()), g.config.autoStateChangeTracking)
								) {
									var a = new Date().getTime() - l,
										c = h(g.config, p);
									t && (c += "#" + t);
									var s = { name: c, url: p.absUrl(), refUri: u, measurements: { routeChangeDuration: a } };
									g.trackPageView(s),
										g.config.enableDebug &&
											console.log("$stateChangeSuccess->trackPageView", c, t, { routeChangeDuration: a, refUri: i });
								}
							});
					}
				]);
		})(n.AppInsightJsV2 || (n.AppInsightJsV2 = {}));
	})((AngularJs = AngularJs || {}));
